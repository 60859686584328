import React, { useEffect, useState } from 'react';
import Alertes from '../../Common/Alertes/Alertes';
import BtnAlertes from '../../Common/BtnAlertes/BtnAlertes';
import Logo from '../../Common/Logo/Logo';
import MenuGeneral from '../../Common/MenuGeneral/MenuGeneral';
import styles from './Header.module.scss';
import { loader } from 'graphql.macro';
import request from '../../../utils/request';
import { UserResponse } from '../../../store/compte/types';
import Loader from '../../Common/Loader/Loader';

const links = [
	// { title: 'Accueil', to: '/' },
	{ title: 'Mes commandes', to: '/commandes' },
	{ title: 'Mes produits', to: '/produits/liste' },
	{ title: 'Mes clients', to: '/clients' },
	{ title: 'Mes actualités', to: '/actualites' },
	{ title: 'Mon compte', to: '/compte' },
	{ title: 'Contact', to: '/contact' },
	{ title: 'Mentions légales', to: '/mentions-legales' },
];

// const loginWithId = loader('./queries/loginWithId.graphql');
const oldAdminQuery = loader('./queries/oldAdmin.graphql');
const loginWithId = loader('./queries/loginWithId.graphql');

function Header() {
	const [oldAdmin, setOldAdmin] = useState({});
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		/* eslint-disable @typescript-eslint/no-unused-vars */
		let isMounted = true;
		setLoading(true);

		type TResponse = { oldAdmin: {} };
		request<TResponse>(oldAdminQuery)
			.then((res) => {
				setOldAdmin(res.oldAdmin);
			})
			.catch((err) => console.log(err.message))
			.finally(() => setLoading(false));

		return () => {
			isMounted = false;
		};
	}, []);

	async function goToAdmin() {
		const { id } = oldAdmin as any;
		await request<UserResponse>(loginWithId, {
			id,
			model: 'Admin',
		});
		window.location.href = '/admin';
	}

	return (
		<header className={styles.wrapper}>
			{loading ? (
				<Loader />
			) : (
				<>
					<Logo className={styles.logo} />

					{Object.keys(oldAdmin).length ? (
						<button className="btnClasse" onClick={() => goToAdmin()}>
							<p className={styles.nom}>Retourner vers l'administration</p>
						</button>
					) : (
						<></>
					)}

					<BtnAlertes />
					<Alertes />

					<MenuGeneral links={links} />
				</>
			)}
		</header>
	);
}

export default Header;
