import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import Table from '../../../../Common/Table/Table';
import { RowProps } from '../../../../Common/Table/types';
import { QueryResponse } from '../SousCategorie';

interface Props extends RouteComponentProps {
	error?: string;
	loading: boolean;
	noResultMessage: string;
	sousCategories: QueryResponse['sousCategories'];
}

export function SousCategorieListe({
	error,
	loading,
	sousCategories,
	noResultMessage,
}: Props) {
	return (
		<Table
			error={error}
			loading={loading}
			noResultMessage={noResultMessage}
			columns={[{ content: 'Nom', center: false }]}
			rows={sousCategories.map(({ id, nom }, i) => {
				return {
					id,
					cells: [
						{
							content: nom,
						},
					],
				} as RowProps;
			})}
		/>
	);
}

export default withRouter(SousCategorieListe);
